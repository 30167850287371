import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import title_image from '../../../images/titles/customer_journey_mapping.jpg'
import person from '../../../images/person.png'

const pageTitle = "Customer Journey Mapping and Experience";
const pageSLug = "customer-journey-mapping-and-experience";
class CustomerJourney extends React.Component{
  render(){
    return(
      <Layout pageType="business-consulting">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Providing a superior customer experience is becoming a critical 
            differentiator between leaders and followers in the market.</h6>
        </div>
      </section>

      <section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    The struggle for competitive advantage has moved to customer experience. If all other elements are the same, 
                    customers consistently prefer to do business with companies that:
                    </h3>
                    <ul>
                        <li>Provide a more flexible and more comfortable way to select a product or service</li>
                        <li>Offer more compelling variations of products and services</li>
                        <li>Have unmatched client experience and service</li>
                        <li>Establishing a high degree of confidence and trust with their customers</li>
                    </ul>
                    <p>It is Nuerex's mission to work with its clients to develop and maintain a customer experience that 
                        creates value. We offer our clients a solution that allows them to consistently and sustainably 
                        increase their customer experience.</p>
                        <span className="break"></span>
                    <h4>How We Can Help Transform the Customer Service Experience</h4>
                    <p>Every company operates to create value, to this end we have established a set of tools that allow our 
                        clients to their understanding of their customer needs, preferences, and expectations as well as the 
                        critical driving variables of customer loyalty and satisfaction. We strive to give your company the 
                        ability to optimize products and services while creating higher value with customers and amounts 
                        delivered.</p>
                        <span className="break"></span>
                    <h4>Customer Research / Profile Development</h4>
                    <p>The most crucial factor in improving customer experience is to understand your customers' desires fully. 
                        Companies must construct customer profiles with the data gathered from all available sources. These may 
                        include direct marketing, web, email, social media, and syndicated data. Having such an elaborate profile 
                        on a customer allows for a single consistent pattern of the customer across the enterprise. Subsequently, 
                        this can serve as the foundation for creating a marketing persona, for delivering enhanced customer service 
                        & care while improving products and services.</p>
                        <span className="break"></span>
                    <h4>Customer Profile Analysis</h4>
                    <p>Profiles of customers can become representations that can guide marketing and management decisions, that can 
                        adapt to fit changing business and market specifications. A good customer profile should include:</p>
                    <ul>
                        <li>Loyalty Drivers</li>
                        <li>Buying Patterns</li>
                        <li>Segmentation</li>
                        <li>Customer Analytics</li>
                        <li>Needs, Preferences, and Expectations</li>
                    </ul>
                    <span className="break"></span>
                    <h4>The Voice of Customers</h4>
                    <p>The best way to understand your customer's needs, wants, and preferences is to ask them. We actively work with 
                        our clients to employ a variety of techniques to gain valuable insights and information. These include but are 
                        not limited to:</p>
                    <ul>
                        <li>Blogs and News</li>
                        <li>Social media, letters, and emails</li>
                        <li>Interviews focus groups and customer surveys</li>
                        <li>Contact center and chat room interactions</li>
                    </ul>
                    <p>The findings are then analyzed to transform the feedback into insights that will help customer experiences and lift 
                        customer expectations, improve products and services, as well as providing early crisis remediation and detection.</p>
                    <span className="break"></span>
                    <h4>Customer Journey Mapping</h4>
                    <p>Customer Journey Mapping is a great exercise when assessing customer experiences and touchpoints from the customer's point 
                        of view. These incredibly discerning maps provide a base for which improvements can be identified, prioritized, and 
                        implemented. Like most exercises, there is no single set mold. Therefore it's crucial to tailor the solution to meet 
                        each client's unique needs and complexities.</p>
                        <span className="break"></span>
                        <div className="contact-box">
                    <h3>Interested in how strategy and vision development can help develop your business?</h3>
                        <span className="break"></span>
                        <div className="button-area">
                            <Link to="/contact-us" className="button default">Contact us today</Link>
                        </div>
                    </div>
                </div>
              {/*  <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default CustomerJourney
